<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <span class="logo">
                            <img
                              src="@/assets/images/logo/logo-dark.png"
                              height="20"
                              alt="logo"
                            />
                          </span>
                        </div>
                        <h4 class="font-size-18 mt-4">Criar Nova Senha</h4>
                        <p class="text-muted">Crie uma nova senha de acesso</p>
                      </div>
                      <div class="p-2 mt-5">
                        <form
                          class="form-horizontal"
                          @submit.prevent="newPassword"
                        >
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon" />
                            <label for="password">Senha</label>
                            <input
                              type="password"
                              id="password"
                              class="form-control"
                              :class="{
                                'is-invalid': $v.password.$error,
                              }"
                              placeholder="Senha"
                              v-model.trim="$v.password.$model"
                            />
                            <div
                              v-if="!$v.password.minLength"
                              class="invalid-feedback"
                            >
                              A senha deve ter pelo menos
                              {{ $v.password.$params.minLength.min }}
                              caracteres
                            </div>
                          </div>
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon" />
                            <label for="confirmPassword">
                              Confirme a Senha
                            </label>
                            <input
                              type="password"
                              id="confirmPassword"
                              class="form-control"
                              :class="{
                                'is-invalid': $v.confirmPassword.$error,
                              }"
                              placeholder="Confirme a Senha"
                              v-model.trim="$v.confirmPassword.$model"
                            />
                            <div
                              v-if="!$v.confirmPassword.$sameAsPassword"
                              class="invalid-feedback"
                            >
                              As senhas devem ser idênticas
                            </div>
                          </div>
                          <div class="mt-4 text-center">
                            <button
                              type="submit"
                              class="btn btn-primary w-md waves-effect waves-light"
                              title="Criar Nova Senha"
                              :disabled="$v.$invalid || disableButton"
                            >
                              <i
                                v-if="disableButton"
                                class="spinner-grow spinner-grow-custom text-wither"
                              />
                              <span v-else>Criar Nova Senha</span>
                            </button>
                          </div>
                          <div class="mt-4 text-center">
                            <router-link
                              to="/"
                              class="text-muted"
                              title="Login"
                            >
                              Login
                            </router-link>
                          </div>
                        </form>
                      </div>
                      <div class="mt-5 text-center">
                        <p>
                          © {{ new Date().getFullYear() }} Go I. Feito com
                          <i class="mdi mdi-heart text-danger" /> por Go I
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import PATHS from '@/router/routesMap'

export default {
  name: 'NewPassword',

  data() {
    return {
      key: this.$route.params.key,
      password: '',
      confirmPassword: '',
      disableButton: false,
    }
  },

  methods: {
    async newPassword() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch('newPassword', {
            key: this.key,
            password: this.password,
            confirmPassword: this.confirmPassword,
          })

          this.resetForm()

          if (response && response.message) {
            this.$root.$bvToast.toast(response.message, {
              title: 'Sucesso!',
              variant: 'success',
              solid: true,
            })
            this.$router.push(PATHS.AUTH)
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        } catch (error) {
          this.resetForm()

          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },

    resetForm() {
      this.disableButton = false
      this.key = ''
      this.password = ''
      this.confirmPassword = ''
      this.$v.$reset()
    },
  },

  validations: {
    password: {
      required,
      minLength: minLength(6),
    },
    confirmPassword: {
      sameAsPassword: sameAs('password'),
    },
  },
}
</script>
